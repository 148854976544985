import React from 'react'

import DefaultLayout from '../components/layout'
import SEO from '../components/seo'

class AboutMePage extends React.Component {
  render() {
    const { data } = this.props

    return (
      <DefaultLayout>
        <SEO title="About Joe Fabisevich, iOS developer who's playing around @ Red Panda Club Inc." />
        <h1>This is where my About page would go.</h1>
        <br />
      </DefaultLayout>
    )
  }
}

export default AboutMePage
